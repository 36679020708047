import { template as template_76e647365f25494aafe7f2129008200c } from "@ember/template-compiler";
const FKLabel = template_76e647365f25494aafe7f2129008200c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
