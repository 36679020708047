import { template as template_ae14c504a25344c78157ba1afc9c5ed2 } from "@ember/template-compiler";
const FKText = template_ae14c504a25344c78157ba1afc9c5ed2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
