import { template as template_4e14f29331344eabb60a53e6fb0b61e4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4e14f29331344eabb60a53e6fb0b61e4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
